var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Modal",
    {
      staticClass: "physicalRegisterUpload",
      attrs: { title: "批量导入", "mask-closable": false },
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _c(
        "div",
        { staticClass: "wrapper", attrs: { id: "excel" } },
        [
          _c(
            "Card",
            [
              _c(
                "Form",
                {
                  ref: "personBatchParam",
                  staticClass: "personForm",
                  attrs: {
                    model: _vm.personParam,
                    rules: _vm.personParamRule,
                    "in-line": "",
                    "label-width": 120,
                  },
                },
                [
                  !_vm.orderId || _vm.orderId.trim().length == 0
                    ? _c(
                        "Row",
                        [
                          _c(
                            "Col",
                            { attrs: { span: "12" } },
                            [
                              _c(
                                "FormItem",
                                {
                                  attrs: { label: "单位名称", prop: "unitId" },
                                },
                                [
                                  _c(
                                    "Select",
                                    {
                                      attrs: {
                                        filterable: true,
                                        "label-in-value": "",
                                      },
                                      on: { "on-select": _vm.unitSelect },
                                      model: {
                                        value: _vm.personParam.unitId,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.personParam,
                                            "unitId",
                                            $$v
                                          )
                                        },
                                        expression: "personParam.unitId",
                                      },
                                    },
                                    _vm._l(_vm.unitArr, function (item, index) {
                                      return _c(
                                        "Option",
                                        {
                                          key: index,
                                          attrs: {
                                            value: item.id,
                                            label: item.name,
                                          },
                                        },
                                        [_vm._v(" " + _vm._s(item.name) + " ")]
                                      )
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "Col",
                            { attrs: { span: "12" } },
                            [
                              _c(
                                "FormItem",
                                {
                                  attrs: {
                                    label: "是否新增订单",
                                    prop: "isAddOrder",
                                  },
                                },
                                [
                                  _c(
                                    "RadioGroup",
                                    {
                                      model: {
                                        value: _vm.personParam.isAddOrder,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.personParam,
                                            "isAddOrder",
                                            $$v
                                          )
                                        },
                                        expression: "personParam.isAddOrder",
                                      },
                                    },
                                    [
                                      _c("Radio", { attrs: { label: "1" } }, [
                                        _vm._v("否"),
                                      ]),
                                      _c("Radio", { attrs: { label: "2" } }, [
                                        _vm._v("是"),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.physicalType == "健康体检"
                    ? _c(
                        "Row",
                        [
                          _c(
                            "Col",
                            { attrs: { span: "12" } },
                            [
                              _c(
                                "FormItem",
                                {
                                  attrs: {
                                    label: "是否默认分组",
                                    prop: "isAddGroup",
                                  },
                                },
                                [
                                  _c(
                                    "RadioGroup",
                                    {
                                      model: {
                                        value: _vm.personParam.isAddGroup,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.personParam,
                                            "isAddGroup",
                                            $$v
                                          )
                                        },
                                        expression: "personParam.isAddGroup",
                                      },
                                    },
                                    [
                                      _c("Radio", { attrs: { label: "1" } }, [
                                        _vm._v("否"),
                                      ]),
                                      _c("Radio", { attrs: { label: "2" } }, [
                                        _vm._v("是"),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm.personParam.isAddGroup == "1"
                            ? _c(
                                "Col",
                                { attrs: { span: "12" } },
                                [
                                  _c(
                                    "FormItem",
                                    {
                                      attrs: {
                                        label: "分组名称",
                                        prop: "groupId",
                                      },
                                    },
                                    [
                                      _c(
                                        "Select",
                                        {
                                          attrs: {
                                            transfer: "",
                                            placeholder: "请选择分组",
                                            clearable: "",
                                          },
                                          on: { "on-change": _vm.groupChange },
                                          model: {
                                            value: _vm.personParam.groupId,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.personParam,
                                                "groupId",
                                                $$v
                                              )
                                            },
                                            expression: "personParam.groupId",
                                          },
                                        },
                                        _vm._l(
                                          _vm.groupList,
                                          function (item, index) {
                                            return _c(
                                              "Option",
                                              {
                                                key: index,
                                                attrs: { value: item.id },
                                              },
                                              [_vm._v(_vm._s(item.name))]
                                            )
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "Row",
                [
                  _c(
                    "Upload",
                    {
                      attrs: {
                        "before-upload": _vm.handleBeforeUpload,
                        action: "",
                        accept: ".xls, .xlsx,.csv",
                      },
                    },
                    [
                      _c(
                        "Button",
                        {
                          attrs: {
                            type: "primary",
                            loading: _vm.uploadLoading,
                            icon: "ios-cloud-upload-outline",
                          },
                          on: { click: _vm.handleUploadFile },
                        },
                        [_vm._v(" 批量导入数据 ")]
                      ),
                      _c(
                        "Button",
                        {
                          attrs: { type: "info" },
                          on: { click: _vm.handleCreateA },
                        },
                        [_vm._v("模板下载")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("Row", [
                _vm.file !== null
                  ? _c(
                      "div",
                      { staticClass: "ivu-upload-list-file" },
                      [
                        _c("Icon", { attrs: { type: "ios-stats" } }),
                        _vm._v(" " + _vm._s(_vm.file.name) + " "),
                        _c("Icon", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.showRemoveFile,
                              expression: "showRemoveFile",
                            },
                          ],
                          staticClass: "ivu-upload-list-remove",
                          attrs: { type: "ios-close" },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.handleRemove()
                            },
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
              _c(
                "Row",
                [
                  _c(
                    "transition",
                    { attrs: { name: "fade" } },
                    [
                      _vm.showProgress
                        ? _c(
                            "Progress",
                            {
                              attrs: {
                                percent: _vm.progressPercent,
                                "stroke-width": 2,
                              },
                            },
                            [
                              _vm.progressPercent == 100
                                ? _c(
                                    "div",
                                    [
                                      _c("Icon", {
                                        attrs: { type: "ios-checkmark-circle" },
                                      }),
                                      _c("span", [_vm._v("成功")]),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.tablePersonData && _vm.tablePersonData.length > 0
            ? _c(
                "Row",
                [
                  _c(
                    "vxe-table",
                    {
                      ref: "tabsValue",
                      attrs: {
                        border: "",
                        resizable: "",
                        data: _vm.tablePersonData,
                        "max-height": 400,
                      },
                    },
                    [
                      _c("vxe-column", {
                        attrs: {
                          field: "personName",
                          title: "姓名",
                          align: "center",
                        },
                      }),
                      _c("vxe-column", {
                        attrs: {
                          field: "idCard",
                          title: "证件号",
                          align: "center",
                        },
                      }),
                      _c("vxe-column", {
                        attrs: {
                          field: "mobile",
                          title: "手机号",
                          align: "center",
                        },
                      }),
                      _c("vxe-column", {
                        attrs: {
                          field: "hazardFactorsText",
                          title: "危害因素",
                          align: "center",
                        },
                      }),
                      _c("vxe-column", {
                        attrs: {
                          field: "otherHazardFactors",
                          title: "其他危害因素",
                          align: "center",
                        },
                      }),
                      _c("vxe-column", {
                        attrs: {
                          field: "workTypeText",
                          title: "工种名称",
                          align: "center",
                        },
                      }),
                      _c("vxe-column", {
                        attrs: {
                          field: "workName",
                          title: "其他工种名称",
                          align: "center",
                        },
                      }),
                      _c("vxe-column", {
                        attrs: {
                          field: "workStateText",
                          title: "在岗状态",
                          align: "center",
                        },
                      }),
                      _c("vxe-column", {
                        attrs: {
                          field: "department",
                          title: "部门",
                          align: "center",
                        },
                      }),
                      _c("vxe-column", {
                        attrs: {
                          field: "errorTxt",
                          title: "错误原因",
                          align: "center",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _c(
                "Row",
                { staticClass: "margin-top-10" },
                [
                  _c("Table", {
                    attrs: {
                      columns: _vm.tableTitle,
                      data: _vm.tableData,
                      loading: _vm.tableLoading,
                      "max-height": "400",
                    },
                  }),
                ],
                1
              ),
        ],
        1
      ),
      _c(
        "div",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "Button",
            { attrs: { type: "text" }, on: { click: _vm.closeAllModal } },
            [_vm._v("取消")]
          ),
          _vm.tablePersonData && _vm.tablePersonData.length > 0
            ? _c(
                "Button",
                { attrs: { type: "primary" }, on: { click: _vm.exportData } },
                [_vm._v("错误数据下载")]
              )
            : _c(
                "Button",
                {
                  attrs: { type: "primary", loading: _vm.uploadLoading },
                  on: { click: _vm.handSubmit },
                },
                [_vm._v("确定")]
              ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }